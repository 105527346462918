import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginAdmin from './Page/Admin/LoginAdmin';
import AdminMain from './Page/Admin/AdminMain';
import Members from './Page/Admin/Members';
import Membersinfo from './Page/Admin/Members-info';
import Memberout from './Page/Admin/MemberOut';
import PushList from './Page/Admin/PushList';
import TransactionList from './Page/Admin/TransactionList';
import TransactionCompleted from './Page/Admin/TransactionCompleted';
import EventList from './Page/Admin/EventList';
import ReviewList from './Page/Admin/ReviewList';
import NoticeList from './Page/Admin/NoticeList';
import CategoryList from './Page/Admin/CategoryList';
import FaqList from './Page/Admin/FaqList';
import QnaList from './Page/Admin/QnaList';
import DeclarationList from './Page/Admin/DeclarationList';
import Footer from './Components/Footer';
//import Setting from './Page/Admin/SettingKo';
import CategoryInfo from './Page/Admin/CategoryInfo.js';
import EventAddform from './Page/Admin/EventAddform.js';
import EventInfo from './Page/Admin/EventInfo.js';
import ReviewInfo from './Page/Admin/ReviewInfo.js';
import MemberoutInfo from './Page/Admin/MemberOut-info';
import TransactionInfo from './Page/Admin/TransactionInfo.js';
import QnaListInfo from './Page/Admin/QnaListInfo';
import FaqListInfo from './Page/Admin/FaqListInfo';
import DeclarationListInfo from './Page/Admin/DeclarationListInfo';
import NoticeListInfo from './Page/Admin/NoticeListInfo';
import PushSend from './Page/Admin/PushSend';
import CategoryAddForm from './Page/Admin/CategoryAddForm';
import NoticeAddForm from './Page/Admin/NoticeAddForm';
import FaqAddForm from './Page/Admin/FaqAddForm';
import DownloadApp from './Page/Admin/downloadApp';
import SettingKo from './Page/Admin/SettingKo';
import SettingEng from './Page/Admin/SettingEng';
import SettingIn from './Page/Admin/SettingIn';
import AdminTransactionlist from './Page//Admin/AdminTransactionlist';
import AdminTransactionform from './Page//Admin/AdminTransactionform';
import AdminTransactionDetail from './Page//Admin/AdminTransactionDetail';

import { Provider } from 'react-redux';
import store from './store';



//import ModifyPw from './Page/Admin/ModifyPw';
//import LockerSettingAdmin from './Page/Admin/LockerSettingAdmin';
//import LockerHistory from './Page/Admin/LockerHistory';
//import MyPage from './Page/MyPage/MyPage';
//import ChangePw from './Page/MyPage/ChangePw';
//import AllCustomersLocker from './Page/Admin/AllCustomersLocker';
//import LockerShowAdmin from './Page/Admin/LockerShowAdmin';
//import Ask from './Page/MyPage/Ask';
//import PaymentHistory from './Page/Admin/PaymentHistory';
//import MembershipInfo from './Page/MyPage/MembershipInfo';
//import RegisterChart from './Page/Main/RegisterChart';
//import EditChart from './Page/Main/EditChart';
//import ChartDetails from 'Page/Admin/ChartDetails';
//import Payment from 'Page/MyPage/Payment';
//import AdSetting from 'Page/Admin/AdSetting';
//import RegisterChartAdmin from 'Page/Admin/RegisterChartAdmin';
//import EditChartAdmin from 'Page/Admin/EditChartAdmin';

function Router() {

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginAdmin />} />
            <Route path="/main" element={<AdminMain />} />
            <Route path="/members" element={<Members />} />
            <Route path="/members-info/:id" element={<Membersinfo />} />
            <Route path="/memberout" element={<Memberout />} />
            <Route path="/memberout-info/:id" element={<MemberoutInfo />}/>
            <Route path="/pushlist" element={<PushList />} />
            <Route path="/push-send" element={<PushSend />} />
            <Route path="/transactionlist" element={<TransactionList />} />
            <Route path="/Transaction-info/:id" element={<TransactionInfo />} />
            <Route path="/transactioncompleted" element={<TransactionCompleted />} />
            <Route path="/eventlist" element={<EventList />} />
            <Route path="/event-addform" element={<EventAddform />} />
            <Route path="/event-info/:idx" element={<EventInfo />} />
            <Route path="/reviewlist" element={<ReviewList />} />
            <Route path="/review-info/:idx" element={<ReviewInfo />} />
            <Route path="/noticelist" element={<NoticeList />} />
            <Route path="/noticelist-info/:id" element={<NoticeListInfo />} />
            <Route path="/notice-addform" element={<NoticeAddForm />} />
            <Route path="/categorylist" element={<CategoryList />} />
            <Route path="/category-info/:idx" element={<CategoryInfo />} />
            <Route path="/category-addform" element={<CategoryAddForm />} />
            <Route path="/faqlist" element={<FaqList />} />
            <Route path="/faqlist-info/:id" element={<FaqListInfo />} />
            <Route path="/faqlist-addform" element={<FaqAddForm />} />
            <Route path="/qnalist" element={<QnaList />} />
            <Route path="/qnalist-info/:id" element={<QnaListInfo />} />
            <Route path="/declarationlist" element={<DeclarationList />} />
            <Route path="/declarationlist-info/:id" element={<DeclarationListInfo />} />
            <Route path="/setting/ko" element={<SettingKo />} />
            <Route path="/setting/eng" element={<SettingEng />} />
            <Route path="/setting/in" element={<SettingIn />} />
            <Route path="/download-app" element={<DownloadApp />} />
            <Route path="/AdminTransactionlist" element={<AdminTransactionlist />} />
            <Route path="/AdminTransactionform" element={<AdminTransactionform />} />
            <Route path="/AdminTransactionDetail/:id" element={<AdminTransactionDetail />} />

            
            <Route path="*" element={<LoginAdmin />} />
          </Routes>
          <Footer />
        </BrowserRouter>
        </Provider>
      </div>
    </div>
  );
}

export default Router;
