import { Table, Input,Button,Select, Popconfirm, Popover, Spin, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

function PushList() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(15);
    const [isHovering, setIsHovering] = useState(false);
    const [onList, setOnList] = useState([]);
    const [display, setDisplay] = useState('none');
    const [no, setNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [screenX, setScreenX] = useState(0);
    const [screenY, setScreenY] = useState(0);
    const handleMouseEnter = (id) => { setIsHovering(id); console.log('isHovering-'+id); }
    const handleMouseLeave = () => { setIsHovering(false); console.log('isHovering-out') }

    const handleClick = (e, id) => {
      setOnList([])
      setIsLoading(true)
      getList(id)
      setScreenX(e.pageX)
      setScreenY(e.pageY)
      setDisplay('block')
    }
    const text = <span>발송대상</span>

    const columns = [
      {
        
        title:"번호",
        key:"index",
        render:((value, item, index) =>no-index )
          
   
       },
        {
       
        title: '발송타입',
        dataIndex: 'pst_type',
        render: (data) => <span>{data === 1 ? '즉시' :  '예약' }</span>,
      },
      {
        
        title: '발송일시',
        dataIndex: 'pst_wdate',
        key: 'idx'
       
 
      },
      {
        
        title: '메세지내용',
        dataIndex: 'pst_content',
         key: 'idx'

        
      },

      {
        
        title: '전달',
        dataIndex: 'pst_target',
        key: 'idx',
        render: (data,row) => (<span 
                                onMouseEnter={() => handleMouseEnter(row.idx)} 
                                onMouseLeave={() => handleMouseLeave()}
                                onClick={(e) => handleClick(e, row.idx)}>
                                {data === 1 ? '즉시' :  '부분' } / <Target color={isHovering === row.idx ? 'black' : 'white'}>{row.pst_count}</Target>
                              </span>),

        // render: (data, row) => (
        //   <PopWrap>
        //     <Popover
        //       placement="bottom"
        //       style={{display: 'flex', flexDirection: 'column'}}
        //       title={text}
        //       // onClcik={() => console.log('d')}
        //       content={()=> {
        //         // setOnList([])
        //         // getList(id)
        //         alert(onList)
        //         {onList.map((data,i) => {
        //           return (
        //             <ListContent>{onList[0].mt_nickname}</ListContent>
        //           )
        //         })}
        //         }
        //       }
        //       trigger="click"
        //     >
        //       <span 
        //         onMouseEnter={() => handleMouseEnter(row.idx)} 
        //         onMouseLeave={() => handleMouseLeave()}
        //         onClick={() => handleClick(row.idx)}
        //       >
        //         {data === 1 ? '즉시' :  '부분' } / <Target color={isHovering === row.idx ? 'black' : 'white'}>{row.pst_count}</Target>
        //       </span>
        //     </Popover>
        //   </PopWrap>
        // )

      },

      {
      
        title: '상태',
        dataIndex: 'push_status',
        key: 'idx',
        render: (data) => <span>{data === "Y" ? '발송완료' :  '발송실패' }</span>,
        
      },
     
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
      console.log(pagination.current);
    }, []);
  
    const getData = async () => {
    
      
      try {
        const formdata = {
          keyword: search,
          page: page,
          amount: amount,
        
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/push-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };

    const getList = async(idx) => {
      try {
        console.log(idx)
        const res = await API.get('admin/push_sendlist?pst_idx='+ idx)
        const resData = res.data
        console.log(resData)
        resData.map((data, i) => {
          return (
            setOnList(onList => [...onList, data])
          )
        })
        console.log(onList)
      }
      catch(error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
      setIsLoading(false);
    }
    
    const reset = async () => {
      setSearch('');
            
      try {
        const formdata = {
          keyword: '',
          page: page,
          amount: amount,
          sfl: '',
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/push-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        console.log(number)
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    

    
  
    useEffect(() => {
      setIsLoading(true);
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount]);


  
    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
       
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const test = (e) => {
      console.log(e.target)
    }

  
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>푸시알림관리</Title>  
                <Button type="primary"  style={{ marginLeft: '5px',height:40 }} onClick={() => navigate('/push-send')} >
                메세지발송
                </Button>
              </Wrap>
              <Wrap >
                
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="메세지 내용을 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                 <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
              </Button>
              <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                초기화
              </Button>
              </Wrap>
            </Wrap>
            <Table
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 15, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='idx'
              
              // scroll={{ x: 1200 }}
            />
            <ListBox style={{display: display, top: screenY+10, left: screenX}} /* x={screenX} y={screenY} */>
              {/* {console.log(screenX)} */}
              <ListWrap>
                <Wrap>발송대상</Wrap>
                <ButtonWrap onClick={() => setDisplay('none')}>X</ButtonWrap>
              </ListWrap>
              {
                isLoading 
                ? <ChartWrap>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Spin tip="Loading...">
                        <Content>
                        </Content>
                      </Spin>
                    </Space>
                  </ChartWrap>
                : <ContentWrap >
                    {onList.map((data,i) => {
                      return (
                        <ListContent>{onList[i].mt_nickname}</ListContent>
                      )
                    })}
                  </ContentWrap>
            }
            </ListBox>
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  const Target = styled.span`
    border-bottom: 1px solid ${props => props.color}
  `

  const ListBox = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    // top: 50%;
    // top: ${props => props.Y};
    // left: ${props => props.X};
    // left: 50%;
    // right: 10%;
    width: 300px;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    box-shadow: 3px 3px 5px lightgray;
    background: white;
    padding: 20px;
    opacity: 2;

  `
  const ListWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    `
  const ButtonWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    `;
    
  const ContentWrap = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  `;

  const ListContent = styled.span`
    margin: 2px 2px;
  `

  const PopWrap = styled.div`
    marginLeft: 70,
    clear: 'both',
    whiteSpace: 'nowrap',
  `
  
const ChartWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  border: 1px solid #d9d9d9;
  padding: 30px;
  margin: 20px;
  width: -webkit-fill-available;
  align-items: stretch;
`
const ChatTitle = styled.h3`
  font-weight: 600;
`
  
  export default PushList;