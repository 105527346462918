import { Table, Input,Button,Select,Modal, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation, useParams } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import TextArea from 'antd/es/input/TextArea';

dayjs.locale('ko');

function MemberoutInfo() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(15);
    const [mtype, setMtype] = useState('');
    const [message, setMessage] = useState(false);
    const idx = useParams();
    const id = idx.id
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const getData = async() => {
      try {
        const res = await API.get(`admin/member-out_info?mt_idx=${id}`);
        const resData = res.data
        console.log(res)
        setData(...resData.data)

      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    }
    
    const deleteMember = async() => {
      try {
        const res = await API.get(`admin/member-delete?mt_idx=${id}`);
        console.log(res.data.message)
        Modal.success({ title: '회원 삭제 완료', content: '회원이 삭제되었습니다.', 
        onOk: () => navigate(`/memberout`), okText: 'Ok' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    }
    
    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    const onSelect = (value) => {
      setSelect(value);
      
    };
    
    const onOutBtn = () => {
      Modal.confirm({ title: '회원 영구 삭제', content: '삭제 하시겠습니까? 삭제한 회원정보는 복구 불가합니다.', 
      onOk: () => deleteMember(), okText: 'Ok' });
    }

    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount]);


  
    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>탈퇴회원 상세</Title>
              </Wrap>
            </Wrap>
            <Wrap>
              <span style={{ marginRight: 10  }}>
                <Button type='Default'  style={{ width: 100, height: 40 }} onClick={() => onOutBtn()}>삭제</Button>
              </span>
            </Wrap>
            <MemberoutTable data={data} id={id}/>
          </Box>
        </Container>
      </div>
    );
  }
  
  function MemberoutTable(props) {
    const [loginUpdate, setLoginUpdate] = useState(false);
    const navigate = useNavigate();
    const phone = props.data.mt_hp
    const memberId = props.data.mt_id
    const nickname = props.data.mt_nickname
    const profileImg = props.data.mt_image1
    const wdate = props.data.mt_wdate // 가입
    const ldate = props.data.mt_ldate // 로그인
    const rdate = props.data.mt_rdate // 탈퇴
    const rmemo = props.data.mt_retire_memo // 탈퇴사유
    const memo = props.data.mt_memo
    const id = props.id

    const loginStatus = props.data.mt_login_status

    const hnickname = props.data.mt_hnickname
    const hdate = props.data.mt_hdate
    let type = '';

    const typeCheck = () => {
      const data = props.data.mt_type;
      switch(data) {
        case 1: type = '일반'
          break;
        case 2: type = '구글'
          break;
        case 3: type = '애플'
          break;
        case 4: type = '라인'
          break;
        case 5: type = '왓츠앱'
          break;
      }
      return type;
    }

    if (props !== null) {
      typeCheck()
    }

    const onRestoreBtn = () => {
      inMember()
    }

    const inMember = async () => {
      try {
        const res = await API.get(`admin/member-in?mt_idx=${id}`);
        const resData = res.data.message
        console.log(resData)
        Modal.success({ title: '회원 복구 완료', content: '회원으로 복구 완료했습니다.', 
        onOk: () => navigate(`/memberout`), okText: 'Ok' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
      }
    }
    return (
      <>
        <div style={{display: 'flex', alignItems: 'flex-start'}}>
          <table>
            <Tr>
              <Th>가입구분</Th>
              <Td>{type}</Td>
            </Tr>
            <Tr>
              <Th>휴대폰번호</Th>
              <Td>{phone}</Td>
            </Tr>
            <Tr style={{marginTop: 60}}>
              <Th>고유아이디</Th>
              <Td>{memberId}</Td>
            </Tr>
            <Tr>
              <Th>닉네임</Th>
              <Td>{nickname}</Td>
            </Tr>
            <Tr style={{marginTop: 30}}>
              <Th>프로필사진</Th>
              <Td>
                <img src={profileImg} 
                   className='custom-avt'
                />
              </Td>
            </Tr>
          </table>
          <table>
            <Tr>
              <Th>가입일시</Th>
              <Td>{wdate}</Td>
            </Tr>
            <Tr>
              <Th>로그인일시</Th>
              <Td>{ldate}</Td>
            </Tr>
            <Tr>
              <Th>로그인 기능</Th>
              <Td>
                <Input value={loginStatus} style={{ width: 90, height: 40, textAlign: 'center'}}/>
              </Td>
            </Tr>
            <Tr>
              <Th>탈퇴일시</Th>
              <Td>{rdate}</Td>
            </Tr>
            <Tr>
              <Th>탈퇴사유</Th>
              <Td>{rmemo === null ? '사유없음' : rmemo}</Td>
            </Tr>
            <Tr style={{height: 50}}>
              <Th>회원탈퇴</Th>
              <Td>
                <Button type='Default' style={{ width: 90, height: 40 }} onClick={() => onRestoreBtn()}>복구</Button>
              </Td> 
            </Tr>
            <Tr>
              <Th></Th>
              <Td>
                <TextArea
                  placeholder='관리자 메모 입력'
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  value={memo === null ? '해당 회원은 메모가 없습니다.' : memo}
                  readOnly
                />
              </Td>
            </Tr>
          </table>
        </div>
        <Line></Line>
        <Wrap style={{justifyContent: 'center'}}>
          <Button type='Default' style={{ width: 90, height: 40, marginRight: 10 }} 
            onClick={() => navigate('/memberout')}>목록</Button>
        </Wrap>
      </>
    )
  }

  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;
  
  const Th = styled.th`
    width: 150px;
  `
  
  const Tr = styled.tr`
    height: 80px;
    text-align: left;
  `

  const Td = styled.td`
    width: 500px;
    padding: 10px;
  `

  const Line = styled.div`
    border-bottom: 1px solid #d9d9d9;
    margin: 90px 0;
  `

  export default MemberoutInfo;