import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorBlack, ColorMainBlue } from '../Utils/Color';
import { Button, Input, message,  Upload,Radio } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

function Eventform({ title, infoData, setInfoData, type, index, size, width = '100%', height = '100%' }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [et_show, setEtShow] = useState('Y');

  const changeValues = (e) => {
   // console.log(e)
    const { value, name } = e.target;
    //console.log(type,index)
    if(name == "et_show"){
     setEtShow(value)
    }
     setInfoData((prev) => ({ ...prev, [type]: { ...prev[type], [index]: { ...prev[type][index], [name]: value } } }));
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadImage = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
    changeValues({ target: { name: 'image', value: info.file.originFileObj } });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return isJpgOrPng;
  };

  const uploadButton = <Button icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>Upload</Button>;

  useEffect(() => {
    const imgURL = infoData.image;
    console.log(imgURL);
    if (typeof imgURL !== 'object') {
      setImageUrl(imgURL);
    } else {
      setImageUrl(null);
    }
  }, [infoData.image]);

  return (
    <>
     
      <Menu>
        <RowWrap>
          <MenuText style={{ alignSelf: 'flex-start' }}>배너이미지({size})</MenuText>
          <Upload name="et_file1" listType="picture" showUploadList={false} beforeUpload={beforeUpload} onChange={uploadImage}>
            {imageUrl ? <img src={imageUrl} alt="et_file1" style={{ width, height }}  className='custom-banner'/> : uploadButton}
          </Upload>
        </RowWrap>
      </Menu>
      
      <Menu>
        <RowWrap>
          <MenuText>내용</MenuText>
          <Input
            name="et_content"
            style={{ display: 'flex', flex: 1, height: 35, borderRadius: 4, borderColor: '#E3E3E3' }}
            placeholder="내용을 입력해 주세요"
            value={infoData.et_content}
            onChange={(e) => changeValues(e)}
          />
        </RowWrap>
      </Menu>
      <Menu>
        <RowWrap>
          <MenuText>노출 여부</MenuText>
          <label style={{ marginRight: '20px' }}>
          <input type="radio"  checked={et_show === "Y"}  onChange={() => changeValues({ target: { name: 'et_show', value: "Y" } })} />
          게시(Y)
        </label> 
        <label>
          <input type="radio" checked={et_show === "N"}  onChange={() => changeValues({ target: { name: 'et_show', value: "N" } })} />
          게시안함(N)
        </label>
          
        </RowWrap>
      </Menu>
    </>
  );
}

const Menu = styled.div`
  padding-top: 5px;
  padding-bottom: 10px;
  &:hover {
    color: ${ColorMainBlue};
    cursor: pointer;
  }
  transition: 0.3s ease;
`;



const RowWrap = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

const MenuText = styled.div`
  color: ${ColorBlack};
  font-size: 15px;
  font-weight: 500;
  min-width: 100px;
  max-width: 100px;
`;

export default Eventform;
