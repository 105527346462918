import React, { useEffect, useState,useCallback } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Button, Divider, Modal, Space, Radio, Input } from 'antd';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate,useParams } from 'react-router-dom';
import CategoryForm from '../../Components/CategoryForm';

function CategoryInfo() {
 const navigate = useNavigate();
 const { idx } = useParams();

 const [infoData, setInfoData] = useState({
    category: { 1: {idx:idx , ct_en_name: '',ct_in_name: '',ct_name: '',ct_rank:'', image: null, ct_show: "Y" } },
   
  });
  
  const fetchData = async () => {
    const res = await API.get('/admin/category-info?idx='+idx);
    const resData = res.data;

    let data = {};
    const types = ['category'];
    for (const type of types) {
      data = { ...data, [type]: { ...infoData[type], ...resData[type] } };
    }
    setInfoData(resData);
    console.log('데이텉너터터텉', data);
  };
  console.log(infoData)
  const onSave = async (type) => {

    try {
      const form = new FormData();
      const category = infoData[type];

      form.append('type', type);
      const idxs = Object.keys(category);

      for (const idx of idxs) {
        const item = category[idx];
       console.log(item);
        if (item.image) {
          form.append('idx', category[1].idx);
          form.append(`ct_en_name`, item.ct_en_name);
          form.append(`ct_in_name`, item.ct_in_name);
          form.append(`ct_name`, item.ct_name);
          form.append(`ct_rank`, item.ct_rank);
          form.append(`image`, item.image);
          form.append(`ct_show`, item.ct_show);
        }
     
      }

      const res = await API.post('/admin/category_add', form, { headers: { 'Content-Type': 'multipart/form-data' } });
 
      Modal.success({ title: '카테고리  등록완료', content: '카테고리가 저장되었습니다.', onOk: () => fetchData(), okText: 'Ok' });
    } catch (error) {
      console.error('카테고리등록 에러', error);
      if (axios.isAxiosError(error)) {
        console.log(error.response);
      }
    }
  };

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ width: '600px' }}>
            <div style={{ width: '100%' }}>
              <Title id="title">카테고리 수정</Title>
            </div>
           
            <Divider />
              <ContentBox>
                <CategoryForm
                  title={'카테고리명'}
                  size={'204x204'}
                  infoData={infoData.category[1]}
                  setInfoData={setInfoData}
                  type="category"
                  index={1}
                  width={'204px'}
                  height={'204px'}
                />
                <Divider />
                <Wrap style={{ marginBottom: 10  }}>
                <div>
                    <span style={{ marginRight: 10  }}> 
                <Button style={{ height: 50, fontSize: 16, borderRadius: 4, marginTop: 20,width:"45%" }} type="default" onClick={() => {
                  navigate(`/categorylist/`);
                 }}>
                  목록
                </Button>
                </span>
                <span> 
                <Button style={{ height: 50, fontSize: 16, borderRadius: 4, marginTop: 20,width:"45%" }} type="primary" onClick={() => onSave('category')}>
                  저장
                </Button>
                </span>
                </div>
                </Wrap>
              </ContentBox>
            
              
          </Wrap>
        </Box>
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;





const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export default CategoryInfo;