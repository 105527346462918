import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { Table, Input, Button, Select, Modal, Space } from 'antd';
import axios from 'axios';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import TextArea from "antd/es/input/TextArea";

function QnaListInfo() {
  const navigate = useNavigate();
  const idx = useParams();
  const id = idx.id
  const [data, setData] = useState({});
  const [qt_answer, setAnswerUpdate] = useState(false);
  const ImgURL = 'https://api.getgo.id/uploads/';
  const [status, setStatus] = useState(false);
  const [reasonInput, setReasonInput] = useState('');

  
  const getData = async () => {
     try {
       const token = sessionStorage.getItem('token');
       const res = await API.get('/admin/qna-detail/?qt_idx='+id, { headers: { Authorization: `Bearer ${token}` } });
       setData(res.data.qna[0]);
       console.log(res.data)
       
     } catch (error) {
       if (axios.isAxiosError(error)) {
         console.log(error.response);
       }
     }
   };
   
  const updateData = async() => {
    const formdata = {
       'qt_idx':id,
       'qt_answer': qt_answer,
       'qt_status': status,
    }
    console.log(formdata)
    try {
      const res = await API.post('admin/qna-edit', formdata, {
        headers : {'Content-Type' : 'application/json'},
      });
      console.log(res)
      Modal.success({ title: '문의 답변 완료', content: '답변작성이 완료되었습니다.', 
      onOk: () => navigate(`/qnalist`), okText: 'Ok' });
    } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
    }
  };

  const deleteData = async() => {
    try {
      const res = await API.get(`admin/qna-delete?qt_idx=${id}`)
      console.log(res.data.message)
      Modal.success({ title: '문의 답변 삭제 완료', content: '답변이 삭제되었습니다.', 
      onOk: () => navigate(`/qnalist`), okText: 'Ok' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response)
      }
    }
  }

  /* 이미지 배열 */
  let imgValArr = Object.values(data)
  let imgURLArr = [];
  const handleImg = () => {  
    imgValArr.map((data, i) => {
      let imgName = `"${data}"`
      let imgNameLower = imgName.toLowerCase()
      if (imgNameLower.includes('png') || imgNameLower.includes('jpg') || imgNameLower.includes('jpeg') || imgNameLower.includes('bmp') || imgNameLower.includes('gif')) {
        imgURLArr.push(data)
      }
    })
  }
  handleImg()

  const onAnswer = (data) => {
    console.log(data);
    setAnswerUpdate(data);
  }

  const onSaveBtn = (e) => {
    if ( qt_answer === false) {
      setReasonInput('답변을 입력해주세요.')
    } else {
      const answerLength = qt_answer.replace(/(\s*)/g, "").length
      console.log(reasonInput)
      console.log(qt_answer)
      // if (reasonInput.length < 2) {
      if (answerLength < 1 ) {
        setReasonInput('답변을 입력해주세요.')
      } else if (!qt_answer) {
        setReasonInput('답변을 입력해주세요.')
      } else {
        updateData();
      }
    }
  }

  const onDeleteBtn = () => {
    Modal.confirm({ title: '문의 답변 삭제', content: '삭제 하시겠습니까?', 
    onOk: () => deleteData(), okText: 'Ok' });
  }

  useEffect(() => {
    const debounce = setTimeout(() => {
      return getData();
    }, 300);
    return () => clearTimeout(debounce);
  }, []);

  return(
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <HeadersAdmin></HeadersAdmin>
      <Container>
        <Box>
          <Wrap style={{ marginBottom: 10 }}>
            <Title>1:1문의상세</Title>
          </Wrap>
          <Wrap style={{ marginBottom: 20, justifyContent: 'space-between' }}>
            <Wrap>
              <Timetext>수정일</Timetext>
              <Time>{data.qt_mdate === null ? '-' : data.qt_mdate}</Time>
            </Wrap>
            <Button type='Default' style={{ width: 100, height: 40 }} onClick={() => onDeleteBtn()}>삭제</Button>
          </Wrap>
          <Article>
            <Category>문의날자</Category>
            <text>{data.qt_wdate}</text>
          </Article>
          <Article>
            <Category>작성자</Category>
            <Content>
              <text>{data.mt_hp}</text>
              <text>{data.mt_nickname}</text>
            </Content>
          </Article>
          <Article style={{alignItems: 'flex-start'}}>
            <Category>문의글</Category>
              <Content>
                <ItemWrap>
                  <Type>참조 이미지</Type>
                  {
                    imgURLArr.map((url,i) => {
                      return (
                        <Img src={`${ImgURL}${url}`} key={i}  className='custom-bas'/>
                      )
                    })
                  }
                </ItemWrap>
                <ItemWrap>
                  <Type>문의내용</Type>
                  <text style={{maxWidth: 700}}>{data.qt_content}</text>
                </ItemWrap>
              </Content>
          </Article>
          <Article style={{alignItems: 'flex-start'}}>
            <Category>답변</Category>
            <Content style={{display: 'flex', width: 1000}}>
              <TextArea
                placeholder='해당 문의에 대한 답변을 기재하세요.'
                autoSize={{ minRows: 3, maxRows: 8 }}
                width='100'
                value={qt_answer === false ? data.qt_answer && onAnswer(data.qt_answer) : qt_answer } 
                onChange={(e) => {onAnswer(e.target.value)}}
              />
              <Message color='#DB473C'>{reasonInput}</Message>
            </Content>
          </Article>
          <Article>
            <Category>답변상태</Category>
              <Select
                type='Default'
                value= {status === false ? data.qt_status && setStatus(data.qt_status) : status}
                style={{ width: 150, textAlign: 'center'}}
                options={[
                  { value: 'Y', label: '답변완료' },
                  { value: 'N', label: '답변대기' },
                ]}
                onChange={(e) => setStatus(e) }
                />
          </Article>
        </Box>
        <Footer>
          <Button type='Default' style={{width: 90, height: 40, marginRight: 10}} onClick={() => navigate('/qnalist')}>목록</Button>
          <Button type='Default' style={{width: 90, height: 40}} onClick={() => onSaveBtn()}>저장</Button>
        </Footer>
      </Container>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: ${ColorWhite};
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 1;
  padding: 50px 80px;
  background-color: ${ColorWhite};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 10px 10px 0;
`;
const Article = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.div`
  color: ${ColorMainBlue};
  font-size: 24px;
  font-weight: bold;
`
const Category = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin: 10px 40px 10px 10px;
  width: 100px;
  min-width: 100px;
  border-right: 1px solid #d9d9d9;
  word-break: keep-all;
`
const Type = styled.div`
  width: 100px;
  font-size: 14px;
  font-weight: 600;
  min-width: 100px;
  word-break: keep-all;
`
const Img = styled.img`
  width: 100px;
  height: 100px;
  background: #d9d9d9;
  margin-right: 15px;
`
const Time = styled.span`
  color: ${ColorMainBlue};
  // margin: 10px;
`
const Timetext = styled.span`
  color: ${ColorMainBlue};
  margin-right: 10px;
  // margin: 10px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 80px;
  justify-content: center;
`
const Message = styled.div`
  padding: 10px 0 0 10px;
  margin: 0;
  color: ${props => props.color};
`
export default QnaListInfo;