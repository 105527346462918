import React, { Component } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; // import styles
import 'bootstrap3/js/modal';
import 'bootstrap3/js/dropdown';
import 'bootstrap3/js/tooltip';
import 'bootstrap3/dist/css/bootstrap.css';
import { API } from '../Utils/API';
import axios from 'axios';

class RichTextEditor extends Component {
  onChange(content) {
    console.log('onChange', content);
    console.log(this)
  }
  render() {
    return (
      <ReactSummernote
        value="Default value"
        insertText={'d'}
        options={{
          width: 1500,
          height: 350,
          dialogsInBody: true,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview']]
          ],
        }}
        onChange={this.onChange}
      />
    );
  }
}

export {RichTextEditor, ReactSummernote};