import { Table, Input,Button,Select, Popconfirm, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';


dayjs.locale('ko');

function AdminTransactionlist() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(15);
    const [onList, setOnList] = useState([]);
    const [no, setNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hover, setHover] = useState('');
 

    const confirm = () => {
      message.info('Searching for the member.');
      
    };

    const columns = [
      {
        
        title:"번호",
        key:"index",
        render:((value, item, index) =>no-index )
          
   
       },
        {
       
        title: '이미지',
        dataIndex: 'pt_image1',
        width:350,
        render:  (data,row) => 
        <span >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}  onMouseEnter={() => setHover(row.pt_idx)} 
        onMouseLeave={() => setHover('')}  >
         <img src={`https://api.getgo.id/uploads/${data}`}  className='custom-bas3'/>
         <div style={{marginLeft:'20px'}}>
          <DIV_Hover 
       className={`${hover !== '' ? 'hover' : 'none'}`} // 호버면 hover 클래스를 추가해주자
     >
       {hover === row.pt_idx && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
           
            <img src={`https://api.getgo.id/uploads/${data}`}  style={{ width: '500px' }}/> 
           
       )}
     </DIV_Hover>
        </div>
        </div>
       
        </span>
            
       

         
      
      },
        
    
      {
        
        title: '제목 및 내용',
        dataIndex: 'pt_title',
        key: 'idx',
        render:  (data,row) => 
        
          <div style={{ display: 'flex', justifyContent: 'space-between' }}   onClick={() => {
            navigate(`/AdminTransactionDetail/${row.pt_idx}`)
          }}>
            <div style={{display: 'flex'}}>
              
              <div style={{  flexDirection: 'column', marginLeft: "10px" }}>
                <div style={{paddingleft:"10px", flexDirection: 'row'}}>[제목] {data}</div>
                <div style={{display: 'flex', alignItems: 'center'}}>[내용] {row.pt_content}</div>
              </div>
            </div>
            <div>
              
            </div>
          </div>
       
 
      },
      {
        
        title: '바로가기',
        dataIndex: 'pt_url',
         key: 'idx',
         render:  (data) => <a href={data} target='_blank' rel='noreferrer'><span>{data}</span></a>,

        
      },
     
      
      {
      
        title: '상태',
        dataIndex: 'pt_sale_now',
        key: 'idx',
        render:  (data,row) => 
          <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {
            navigate(`/AdminTransactionDetail/${row.pt_idx}`)
          }}>
            <div style={{display: 'flex'}}>
              
              <div style={{  flexDirection: 'column', marginLeft: "10px" }}>
                <div style={{paddingleft:"10px", flexDirection: 'row'}}>{data === "1" ? '진행중' :  '종료' } </div>
                {data === "1" && row.pt_reg_date ?
                <div style={{display: 'flex', alignItems: 'center'}}>{row.pt_reg_date} </div>
                :''}
              </div>
            </div>
            <div>
              
            </div>
          </div>
       
        
      },
     
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
      console.log(pagination.current);
    }, []);
  
    const getData = async () => {
    
      
      try {
        const formdata = {
          keyword: search,
          filter: filter,
          page: page,
          amount: amount,
        
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/product-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };

    const getList = async(idx) => {
      try {
        console.log(idx)
        const res = await API.get('admin/product-list')
        const resData = res.data
        console.log(resData)
        resData.map((data, i) => {
          return (
            setOnList(onList => [...onList, data])
          )
        })
        console.log(onList)
      }
      catch(error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
      setIsLoading(false);
    }
    
    const reset = async () => {
      setSearch('');
            
      try {
        const formdata = {
          keyword: '',
          filter: 'all',
          page: page,
          amount: amount,
          
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/product-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
        console.log("리셋",res)
        setData(res.data.list);
        setTotal(res.data.total);
        setSelect('all');
        setSearch('');
        const number = res.data.total-(page - 1) * amount;
        console.log(number)
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    
    const onSelect = (value) => {
        setSelect(value);
        
      };
    
    
      

    
  
    useEffect(() => {
      setIsLoading(true);
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount]);


  
    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
       
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const test = (e) => {
      console.log(e.target)
    }

  
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>운영자 거래 등록</Title>  
                <Button type="primary"  style={{ marginLeft: '5px',height:40 }} onClick={() => navigate('/AdminTransactionform')} >
                신규둥록
                </Button>
              </Wrap>
              <Wrap >
              <Select onChange={onSelect}
                  defaultValue="all" value={filter}
                  style={{ width: 120,height:40 }}
                  options={[
                    { value: 'all', label: '전체' },
                    { value: '1', label: '진행중' },
                    { value: '3', label: '종료' }
                   
                  ]}
                />
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="검색어를 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                 <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
              </Button>
              <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                초기화
              </Button>
              </Wrap>
            </Wrap>
            <Table
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 15, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='idx'
              
              // scroll={{ x: 1200 }}
            />
           
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  const Target = styled.span`
    border-bottom: 1px solid ${props => props.color}
  `

  const ListBox = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    // top: 50%;
    // top: ${props => props.Y};
    // left: ${props => props.X};
    // left: 50%;
    // right: 10%;
    width: 300px;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    box-shadow: 3px 3px 5px lightgray;
    background: white;
    padding: 20px;
    opacity: 2;

  `
  const ListWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    `
  const ButtonWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    `;
    
  const ContentWrap = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  `;

  const ListContent = styled.span`
    margin: 2px 2px;
  `

  const PopWrap = styled.div`
    marginLeft: 70,
    clear: 'both',
    whiteSpace: 'nowrap',
  `
  
const ChartWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  border: 1px solid #d9d9d9;
  padding: 30px;
  margin: 20px;
  width: -webkit-fill-available;
  align-items: stretch;
`
const ChatTitle = styled.h3`
  font-weight: 600;
`


const NicknameBtn = styled.div`
    border: none,
    background: transparent,
    &:hover {
    border: 1px solid red;
    }
    `


    const DIV_Hover = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: top 1s ease-in; 
    top: 20px; 		      
    left:0
    &.hover { 			
      top: 0px; 			
      animation-duration: 3s;  	
      animation-name: fadeout; 	
    }
    `
  export default AdminTransactionlist;