import { configureStore, createSlice } from "@reduxjs/toolkit";

let openMenu = createSlice({
  name: 'name',
  initialState: 'main1',
  reducers: {
    changeMenu(state, a){
      // return state
      return a.payload
    }
  }
})

export let {changeMenu} = openMenu.actions

export default configureStore({
  reducer: {
    openMenu : openMenu.reducer,
  }
})