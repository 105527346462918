import { Table, Input, Button, Select, Modalimport, Space, Dropdown, Modal, Upload, message, Carousel } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite, ColorRed } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import TextArea from 'antd/es/input/TextArea';
import '../../../src/style.css'
import { DownOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import Footer from '../../Components/Footer';

dayjs.locale('ko');

function Membersinfo() {
    const navigate = useNavigate();
    const location = useLocation();
    const initialDetailState = {
      ct_name :'',
      pt_content : '',
      pt_idx : null,
      pt_image1 : '',
      pt_image2 : '',
      pt_image3 : '',
      pt_image4 : '',
      pt_image5 : '',
      pt_image6 : '',
      pt_image7 : '',
      pt_image8 : '',
      pt_image9 : '',
      pt_image10 : '',
      pt_selling_price : null,
      pt_show : '',
      pt_title : '',
      pt_wdate : '',
    }

    const [data, setData] = useState([]);
    const [detail,setDetail] = useState(()=>initialDetailState);
    const [dateTag, setDateTag] = useState(false);
    const [detailToggle, setDetailToggle] = useState(false);
    const [itemlist, setItemlist] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(15);
    const [mtype, setMtype] = useState('');
    const [tabBtn, setTabBtn] = useState(true);
    const [display, setDisplay] = useState('none');
    const [imgURLArr, setImgURLArr] = useState([]);
    const ImgURL = 'https://api.getgo.id/uploads/';
    const [no, setNumber] = useState(0);
    const idx = useParams();
    const id = idx.id

    itemlist.map((data, i) => {
      const numb = data.ct_id
      const state = data.pt_sale_now
      const itemImg = data.pt_image
      const categoryName = data.ct_name
      const seller = data.mt_seller_nickname
      const buyer = data.mt_nickname
      const dataSource = [
        {
          key: {i},
          numb: {numb},
          state: <span>{state === 1 ? '판매중' : (state === 2) ? '예약중' : '거래완료'}</span>,
          item: <span>{itemImg}{categoryName}</span>,
          seller: {seller},
          buyer: {buyer},
        },
      ]
      // console.log(dataSource)
    })

    const dataSource = [
      {
        key: '1',
        numb: '1',
        state: '판매중',
        item: '상품정보',
        seller: '판매자명',
        buyer: '구매자명',
      },
      {
        key: '2',
        numb: '2',
        state: '판매중',
        item: '상품정보',
        seller: '판매자명',
        buyer: '구매자명',
      },
    ];
    const columns = [
      {
        
        title:"번호",
        key:"index",
        render:((value, item, index) =>no-index )
          
   
       },
      {
        title: '거래상태',
        dataIndex: 'pt_sale_now',
        width: 200,
        render: (itemlist) => <span>{itemlist === '1' ? '판매중' : (itemlist === '2') ? '예약중' : '거래완료'}</span>
        // key: 'idx',
      },
      {
        
        title: '상품정보',
        dataIndex: 'pt_title',
        key: 'idx',
        ellipsis: true,
        width: 600,

      render:  (data,row) => <div style={{display:'block', marginRight: 20}} >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'row'}}>
            <div style={{marginright: "10px", display: 'flex', flexDirection: 'column'  }}>
              <img src={`https://api.getgo.id/uploads/${row.pt_image1}`}  className='custom-bas'/>
            </div>
            <div style={{  flexDirection: 'column', marginLeft: "10px" }}>
              <div style={{paddingleft:"10px", flexDirection: 'row'}}>[{row.ct_name ? row.ct_name : '카테고리없음'}]</div>
              <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>{data.length > 30 ? `${data.substr(0,30)}...` : data}
            </div>
          </div>
        </div>
        <div>
          {(row.pt_show === 'Y' 
            ? <Button 
                style={{display: 'flex', alignItems: 'center'}}
                onClick={(e) => getDetail(row.pt_idx)} type="default"
              >상세</Button>
            : <DeleteItem>삭제된 상품</DeleteItem>
          )}
        </div>
      </div>
    </div>
 
      },
      {
        title: '판매자',
        dataIndex: 'mt_seller_nickname',
         key: 'idx',
         render:  (data,row) => <span><div>{data}</div><div>{row.mt_seller_hp}</div></span>
      },

      {
        title: '구매자',
        dataIndex: 'mt_nickname',
        key: 'idx',
        render:  (data,row) => <span><div>{data ? data : <Message color='#d9d9d9' style={{padding: 0}}>닉네임없음</Message>}</div><div>{row.mt_hp}</div></span>
      },
    ];
    
    const getData = async () => {
      try {
        const res = await API.get('admin/member-info?mt_idx='+id);
        const resData = res.data
        setData(...resData.data)
        setItemlist(resData.itemlist)
        const number = res.data.total-(page - 1) * amount;
        console.log(res.data.itemlist.length + 1) // 총 개수
        console.log('number',number)
        setNumber(number);
        console.log(res)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
    console.log(data)
    
    const getDetail = async (idx) => {
      try {
        const res = await API.get('admin/product-infolist?pt_idx='+idx);
        const resData = res.data
        console.log('getDetail: ',res)
        setDetail(resData.data[0])
        ImgCarousel(Object.values(resData.data[0]))
        console.log(`detail데이터`,resData.data[0])
        detailDate()
        setDetailToggle(true)

      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    }

    /* 이미지 배열 */
    let imgValArr = '';
    const ImgCarousel = (detailData) => {
      setImgURLArr([])
      imgValArr = detailData
      imgValArr.map((data, i) => {
        let imgName = `"${data}"`
        let imgNameLower = imgName.toLowerCase()
        if (imgNameLower.includes('png') || imgNameLower.includes('jpg') || imgNameLower.includes('jpeg') || imgNameLower.includes('bmp') || imgNameLower.includes('gif')) {
          imgURLArr.push(data)
          setImgURLArr((e) => [...e, data] )
        }
      })
      console.log(imgURLArr)
    }
    console.log('imgURLArr',imgURLArr)

    const detailDate = () => {
      const wdate = detail.pt_wdate
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1) > 9 ? (now.getMonth() + 1) : '0' + (now.getMonth() + 1);
      const date = now.getDate() > 9 ? now.getDate() : '0' + now.getDate();
      const hour = now.getHours();
      const minute = now.getMinutes();

      const today = `${year}-${month}-${date}`

      const itemDate = wdate.slice(0,10);
      const itemHour = wdate.slice(11,13)*1;
      const itemMinute = wdate.slice(14,16)*1;

      let time = '';
      console.log('itemDate', itemDate)
      console.log('today', today)
      console.log(itemHour)
      console.log(itemMinute)
      console.log(minute)

      if ( today === itemDate){
        console.log('오늘 올라온 상품')
        if ( hour === itemHour ) {
          if ( minute === itemMinute ) {
            setDateTag(`방금 전`)
          } else {
            time = (minute = itemMinute)
            setDateTag(`${minute - itemMinute} 분 전`)
          }
        } else {
          time = (hour - itemHour)
          setDateTag(`${hour - itemHour} 시간 전`)
        }
      } else {
        setDateTag(itemDate)
      }
      console.log(now)
      console.log(`now: ${year}-${month}-${date}`)
      console.log(date)
      console.log('상품날짜: ',wdate.slice(0,10))
    }

    const onChangeImg = (currentSlide) => {
      console.log(currentSlide);
    };

    const onClose = () => {
      setImgURLArr([])
      setDetailToggle(false)
    }

    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const onSelect = (value) => {
      setSelect(value);
    };
    
    useEffect(()=>{
      setDetailToggle(false)
    },[detail])

    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount]);


    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <ItemContainer dp={detailToggle} style={{flex: 1/2}}>
            <Box style={{paddingRight: 0, paddingBottom: 20}}>
              <Wrap style={{justifyContent: 'space-between'}}>
                <Title style={{marginBottom: 10}}>상품정보</Title>
                <Button onClick={() => onClose()}>X</Button>
              </Wrap>
              <Carousel afterChange={onChangeImg}>
                {
                  imgURLArr.map((url,i) => {
                    console.log(i, url)
                    return (
                      <div>
                        <Img src={`${ImgURL}${url}`} key={`{img-}${i}`}  className='custom-bas'/>
                      </div>
                    )
                  })
                }
              </Carousel>
            </Box>
            <ItemBox>
              <ItemWrap style={{justifyContent: 'space-between'}}>
                <Tag>{detail.ct_name ? detail.ct_name : '카테고리 없음'}</Tag>
                <Time>{dateTag ? dateTag : () => detailDate()}</Time>
              </ItemWrap>
              <ItemWrap style={{display: 'block'}}>
                <ItemTitle>{detail.pt_title}</ItemTitle>
                <text>{detail.pt_content}</text>
                <Line/>
                <Price>가격 {detailToggle ? (detail.pt_selling_price).toLocaleString() : null }</Price>
              </ItemWrap>
            </ItemBox>
          </ItemContainer>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>회원상세</Title>
              </Wrap>
            </Wrap>
            <Wrap style={{ marginBottom: 30  }}>
              <span style={{ marginRight: 10  }}>
                <Button type={tabBtn ? 'primary' : 'Default'} style={{ width: 100, height: 40 }} onClick={() => setTabBtn(true)}>
                  기본정보
                </Button>
              </span>
              <span style={{ marginRight: 10  }}>
                <Button type={tabBtn ? 'Default' : 'primary'} style={{ width: 100, height: 40 }} onClick={() => setTabBtn(false)}>
                  거래정보
                </Button>
              </span>
            </Wrap>
            {tabBtn ? <MemberTable data={data} id={id}/> : <CountTable data={{columns, data, itemlist}}/>}
          </Box>
        </Container>
        {/* <Footer /> */}
      </div>
    );
  }
  
  function MemberTable(props) {
    const navigate = useNavigate();
    const [phonecheck, setPhonecheck] = useState(false);
    const [phoneUpdate, setPhoneUpdate] = useState(false);
    const [nickUpdate, setNickUpdate] = useState(false);
    const [nickcheck, setNickcheck] = useState(false);
    const [memoUpdate, setMemoUpdate] = useState(false);
    const [loginUpdate, setLoginUpdate] = useState(false);
    const [reasonInput, setReasonInput] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageName, setImageName] = useState(false);
    

    const phone = props.data.mt_hp
    const mtId = props.data.mt_id
    const nickname = props.data.mt_nickname
    const profileImg = props.data.mt_image1
    const wdate = props.data.mt_wdate // 회원가입일시
    const ldate = props.data.mt_ldate // 로그인일시
    const loginStatus = props.data.mt_login_status
    const memo = props.data.mt_memo
    const hnickname = props.data.mt_hnickname
    const hdate = props.data.mt_hdate
    let type = '';
    const typeCheck = () => {
      const data = props.data.mt_type;
      switch(data) {
        case 1: type = '일반'
          break;
        case 2: type = '구글'
          break;
        case 3: type = '애플'
          break;
        case 4: type = '라인'
          break;
        case 5: type = '왓츠앱'
          break;
      }
      return type;
    }

    if (props !== null) {
      typeCheck()
    }
    const onOutBtn = () => {
      if ( memoUpdate === false) {
        setReasonInput('회원탈퇴 사유를 입력해주세요.')
      } else {
        const memoLength = memoUpdate.replace(/(\s*)/g, "").length
        console.log(reasonInput)
        console.log(memoUpdate)
        // if (reasonInput.length < 2) {
        if (memoLength < 1 ) {
          setReasonInput('회원탈퇴 사유를 입력해주세요.')
        } else if (!memoUpdate) {
          setReasonInput('회원탈퇴 사유를 입력해주세요.')
        } else {
          Modal.confirm({ title: '회원 탈퇴', content: '탈퇴처리 하시겠습니까?', 
          onOk: () => deleteMember(), okText: 'Ok' });
        }
      }
    }

    const deleteMember = async () => {

      try {
        const res = await API.get(`admin/member-out?mt_idx=${props.id}&mt_retire_memo=${memoUpdate}`);
        const resData = res.data.message
        console.log(resData)
        Modal.success({ title: '회원 탈퇴 완료', content: '회원 탈퇴 되었습니다.', 
        onOk: () => navigate(`/memberout`), okText: 'Ok' });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response)
        }
      }
    }

    const checkPhone = async () => {
      const data = {'mt_idx': props.id, 'mt_hp': phoneUpdate}
      console.log(phoneUpdate)
      try {
        const res = await API.post('admin/change_hp_check', data, {
          headers : {'Content-Type' : 'application/json'},
        });
        setPhonecheck(res.data.message)
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
          setPhonecheck(error.response.data.message)
        }
      }
    };

    const checkNickname = async () => {
      const data = {'mt_idx': props.id, 'mt_nickname': nickUpdate}
      try {
        const res = await API.post('admin/change_nick_check', data, {
          headers : {'Content-Type' : 'application/json'},
        });
        setNickcheck(res.data.message)
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
          setNickcheck(error.response.data.message)
        }
      }
    };

    const updateMember = async () => {
      let file= '';
      let contentType ='';
      if (imageUrl !== null) {
        file = dataURLtoFile(imageUrl, imageName)
        contentType = 'multipart/form-data';
      } else {
        file = profileImg
        contentType = 'application/json';
      }
      console.log(file)
      // const bodyData = {
      //   'mt_idx': props.id,
      //   'mt_hp': phoneUpdate,
      //   'mt_login_status': loginUpdate,
      //   'mt_nickname': nickUpdate,
      //   'mt_memo': memoUpdate,
      //   'mt_image1' : (imageUrl ? file : profileImg)
      // }
      // console.log(bodyData)
      try {
        const bodyData = new FormData();
        
        bodyData.append('mt_idx', props.id,);
        bodyData.append('mt_hp', phoneUpdate,);
        bodyData.append('mt_login_status', loginUpdate,);
        bodyData.append('mt_nickname', nickUpdate,);
        bodyData.append('mt_memo', memoUpdate,);
        bodyData.append('mt_image1', (imageUrl ? file : profileImg));
        
        for (let key of bodyData.keys()) {
          console.log(key, ":", bodyData.get(key));
        }
        
        const res = await API.post('admin/member-edit', bodyData, {
          // headers : {'Content-Type' : 'application/json'},
          headers : {'Content-Type' : contentType},
        });
        Modal.success({ title: '회원 수정 완료', content: '회원 정보가 수정되었습니다.', 
        onOk: () => navigate(`/members`), okText: 'Ok' });
          
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      }
    };

    const onSaveBtn = () => {
      updateMember()
    }

    // base64 Data -> File 객체로 변환
    const dataURLtoFile = (dataurl, filename) => {
    // function dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }



    
    const changeValues = (e) => {
      console.log(e)
      console.log(imageUrl)
      const { value, name } = e.target;

      //  setInfoData((prev) => ({ ...prev, [type]: { ...prev[type], [index]: { ...prev[type][index], [name]: value } } }));
     };

    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
      console.log(img)
    };

    const uploadImage = (info) => {
      console.log(info.file)
      setImageName(info.file.name)
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      setImageUrl(info.file.originFileObj)
      // changeValues({ target: { name: 'mt_image1', value: info.file.originFileObj } });
    };

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      return isJpgOrPng;
    };

    const uploadButton = <Button icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>Upload</Button>;

    // useEffect(() => {
    //   const imgURL = infoData.image;
    //   console.log(imgURL);
    //   if (typeof imgURL !== 'object') {
    //     setImageUrl(imgURL);
    //   } else {
    //     setImageUrl(null);
    //   }
    // }, [infoData.image]);

    return (
      <>
        <div style={{display: 'flex'}}>
          <table>
            <Tr>
              <Th>가입구분</Th>
              <Td>{type}</Td>
            </Tr>
            <Tr>
              <Th>휴대폰번호</Th>
              <Td>
                <div /* style={{display: 'flex', flexDirection: 'row'}} */>
                  <Input style={{ width: 250, height:40, marginLeft: '5px' }} 
                    value={phoneUpdate === false ? (phone && setPhoneUpdate(phone)) : phoneUpdate} 
                    onChange={(e) => { setPhoneUpdate(e.target.value) }}
                  />
                  <Button type='Default' style={{ width: 90, height: 40 }} onClick={() => checkPhone()}>변경</Button>
                </div>
                {!phonecheck 
                  ? <>
                      <Text>{hdate === null ? '번호변경내역 없음' : `번호변경: ${hdate}`}</Text>
                    </>
                  : <Message color={phonecheck === '사용가능한 휴대폰번호입니다.' ? '#162D59' : '#DB473C'}>
                      {phonecheck}
                    </Message>
                }
              </Td>
            </Tr>
            <Tr style={{marginTop: 60}}>
              <Th>고유아이디</Th>
              <Td>{mtId}</Td>
            </Tr>
            <Tr>
              <Th>닉네임</Th>
              <Td>
                <Input style={{ width: 250,height:40, marginLeft: '5px' }} 
                  value={nickUpdate === false ? (nickname && setNickUpdate(nickname)) : nickUpdate} 
                  onChange={(e) => { setNickUpdate(e.target.value)}}
                />
                <Button type='Default' style={{ width: 90, height: 40 }} onClick={() => checkNickname()}>중복확인</Button>
                {!nickcheck 
                  ? null
                  : <Message color={nickcheck === '사용가능한 닉네임입니다.' ? '#162D59' : '#DB473C'}>
                      {nickcheck}
                    </Message>
                }
              </Td>
            </Tr>
            <Tr style={{marginTop: 30}}>
              <Th>프로필사진</Th>
              <Td>
                {/* <img src={profileImg} 
                  crossOrigin='anonymous' className='custom-avt'
                /> */}
                <Upload name="ct_file1" listType="picture" showUploadList={false} beforeUpload={beforeUpload} onChange={uploadImage}>
                  { imageUrl 
                    ? <img src={imageUrl} alt="ct_file1" style={{ width: 60, height: 60, borderRadius: '50%' }}  /> 
                    : !imageUrl && profileImg
                    ? <img src={profileImg} alt="ct_file1" style={{ width: 60, height: 60, borderRadius: '50%' }}  /> 
                    : uploadButton
                  }
                </Upload>
              </Td>
            </Tr>
          </table>
          <table>
            <Tr>
              <Th>가입일시</Th>
              <Td>{wdate}</Td>
            </Tr>
            <Tr>
              <Th>로그인일시</Th>
              <Td>{ldate}</Td>
            </Tr>
            <Tr>
              <Th>로그인 기능</Th>
              <Td>
                <Select
                  type='Default'
                  value={loginUpdate === false ? (loginStatus && setLoginUpdate(loginStatus)) : loginUpdate}
                  style={{ width: 90, textAlign: 'center' }}
                  onChange={(e) => setLoginUpdate(e)}
                  options={[
                    { value: 'Y', label: '가능' },
                    { value: 'N', label: '불가능' },
                  ]}
                />
              </Td>
            </Tr>
            <Tr style={{height: 50}}>
              <Th>회원탈퇴</Th>
              <Td>
                <Button type='Default'  style={{ width: 90, height: 40 }} onClick={() => onOutBtn()}>탈퇴</Button>
              </Td> 
            </Tr>
            <Tr>
              <Th></Th>
              <Td>
                <TextArea
                  placeholder='회원탈퇴 사유 입력'
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  value={memoUpdate === false ? (memo && setMemoUpdate(memo)) : memoUpdate } onChange={(e) => {setMemoUpdate(e.target.value)}}
                />
                <Message color='#DB473C'>{reasonInput}</Message>
              </Td>
            </Tr>
          </table>
        </div>
        <Line></Line>
        <Wrap style={{justifyContent: 'center'}}>
          <Button type='Default' style={{ width: 90, height: 40, marginRight: 10 }} 
            onClick={() => navigate(`/members`)}>목록</Button>
          <Button type='Default' style={{ width: 90, height: 40 }} onClick={() => onSaveBtn()}>저장</Button>
        </Wrap>
      </>
    )
  }

  function CountTable(props) {
    const data = props.data.data
    const itemlist = props.data.itemlist
    const columns = props.data.columns
    const navigate = useNavigate();
    return(
      <>
        <table>
          <Tr style={{borderBottom: '1px solid #d9d9d9'}}>
            <Th>판매건수</Th>
            <Td>{data.sale_cnt}건</Td>
          </Tr>
          <Tr style={{borderBottom: '1px solid #d9d9d9'}}>
            <Th>구매건수</Th>
            <Td>{data.purchase_cnt}건</Td>
          </Tr>
          <Tr style={{height: 40}}>
            <Th>만족도수</Th>
            <Td>별로예요</Td>
            <Td>좋아요</Td>
            <Td>최고예요</Td>
          </Tr>
          <Tr style={{borderBottom: '2px solid #162D59'}}>
            <Th></Th>
            <Td>{data.rt_score1}건</Td>
            <Td>{data.rt_score2}건</Td>
            <Td>{data.rt_score3}건</Td>
          </Tr>
        </table>
        <Table style={{minWidth: 1400}}dataSource={itemlist} columns={columns} rowKey='idx'/>
        <Wrap style={{justifyContent: 'center'}}>
        <Button type='Default' style={{ width: 90, height: 40, marginRight: 10 }} 
            onClick={() => navigate(`/members`)}>목록</Button>
        </Wrap>
      </>
    )
  }
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;
  
  const Th = styled.th`
    width: 150px;
    margin-top: 20px;
  `
  
  const Tr = styled.tr`
    display: flex;
    // min-width: 700px;
    width: 100%;
    height: 80px;
    text-align: left;
    `
    
    const Td = styled.td`
    width: 500px;
    margin-top: 20px;
    // padding: 10px;
  `

  const Line = styled.div`
    border-bottom: 1px solid #d9d9d9;
    margin: 90px 0;
  `
  const Text = styled.p`
    padding: 10px 0 0 10px;
    margin: 0;
    color: #162d59;
  `
  const Message = styled.p`
    padding: 10px 0 0 10px;
    margin: 0;
    color: ${props => props.color};
  `

  const DeleteItem = styled.p`
    margin: 0;
    padding-left: 10px;
    color: ${ColorRed};
  `

  const ItemContainer = styled.div`
    display: ${props => props.dp === true ? 'block' : 'none'};
    flex: 1/2;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `

  const ItemBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 0 0 80px;
    background-color: ${ColorWhite};
  `;

  const ItemWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
  `;

  const Img = styled.img`
    width: 100%;
    height: 500px;
    background: #d9d9d9;
  `

  const Tag = styled.span`
    // width: 100px;
    height: auto;
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    // border-radius: 5px;
    padding: 0 10px;
    text-align: center;
    font-weight: 600;
  `
  const Time = styled.span`
    color: ${ColorMainBlue};
    // margin: 10px;
  `
  const ItemTitle = styled.h2`
    font-size: 20px;
    font-weight: 600;
  `
  const ItemLine = styled.div`
    width: auto;
    height: 1px;
    background: #d9d9d9;
    margin: 100px 0 20px 0;
  `
  const Price = styled.p`
    font-size: 20px;
    font-weight: 600;
  `
  export default Membersinfo;