import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App';
import '../src/lang/i18n';



ReactDOM.render(
	// <React.StrictMode>
	// </React.StrictMode>,
  
	<App />,
	document.getElementById('root')
);
