import { Table, Input,Button,Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

function Memberout() {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(15);
    const [no, setNumber] = useState(0);
    

    


    const columns = [
        {
        
          title:"번호",
          key:"index",
          render:((value, item, index) =>no-index )
            
     
         },
        {
       
        title: '가입 구분',
        dataIndex: 'mt_type',
        render: (data) => <span>{data === 1 ? '일반' : (data === 2) ? '구글' : (data === 3) ? '애플' : (data === 4) ? '라인' : '왓츠앱'}</span>,
      },
      {
        
        title: '휴대폰 번호',
        dataIndex: 'mt_hp',
        key: 'idx'
       
 
      },
      {
        
        title: '이메일',
        dataIndex: 'mt_email',
         key: 'idx'

        
      },
      {
        
        title: '고유아이디',
        dataIndex: 'mt_id',
         key: 'idx'

        
      },

      {
        
        title: '탈퇴일시',
        dataIndex: 'mt_rdate',
        key: 'idx',
        render: (data) => (<span>{dayjs(data).format('YYYY.MM.DD(dd)HH:mm')}</span>),

      },

      {
      
        title: '관리',
        dataIndex: 'idx',
        key: 'idx',
        render: (data, row) =>
        data === 'initial' ? (
          <span>-</span>
        ) : (
          <Button
            onClick={() => {
              navigate(`/memberout-info/${data}`);
            }}
            // disabled={row.refundAmount}
            type="primary"
          >
            상세
          </Button>
        ),
        
      },
     
    ];
  
    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const getData = async () => {
    
      
      try {
        const formdata = {
          keyword: search,
          page: page,
          amount: amount,
          sfl: filter,
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/memberout-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
         setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
        setNumber(number);
        console.log(res.data.list)
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };


    const reset = async () => {
      setSearch('');
      setSelect('all');
      
      try {
        const formdata = {
          keyword: '',
          page: page,
          amount: amount,
          sfl: 'all',
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/memberout-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
        const number = res.data.total-(page - 1) * amount; 
        setData(res.data.list);
        setTotal(res.data.total);
        setNumber(number);
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };
  
    const onKeyword = (e) => {
      setPage(1);
      setSearch(e.target.value);
    };

    
    
    const onSelect = (value) => {
      setSelect(value);
      
    };
    
  
    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount]);


  
    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };


  
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>탈퇴회원관리 (총 탈퇴회원수<span>{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>명)</Title>
              </Wrap>
              <Wrap >
                <Select onChange={onSelect}
                  defaultValue="all" value={filter}
                  style={{ width: 120,height:40 }}
                  options={[
                    { value: 'all', label: '통합검색' },
                    { value: 'mt_hp', label: '연락처' },
                    { value: 'mt_id', label: '고유아이디' },
                    { value: 'mt_nickname', label: '닉네임' },
                  
                  ]}
                />
                <Input
                  style={{ width: 250,height:40, marginLeft: '5px' }}
                  name="search"
                  placeholder="검색어를 입력해 주세요"
                  value={search}
                  onChange={onKeyword}                
                />
                 <Button type="primary"  style={{ marginLeft: '5px',height:40 }}  onClick={getData}>
                검색
              </Button>
              <Button type='Default'  style={{ marginLeft: '5px',height:40 }}  onClick={reset}>
                초기화
              </Button>
              </Wrap>
            </Wrap>
           
            <Table
              
              columns={columns}
              dataSource={data}
              onChange={onChange}
              style={{ borderTop: '2px solid #162D59', cursor: 'pointer', }}
              pagination={{ total: total, pageSize: 15, showSizeChanger: false, current: page }}
              showSorterTooltip={false}
              rowKey='idx'
              
              // scroll={{ x: 1200 }}
            />
          </Box>
        </Container>
      </div>
    );
  }
  
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;

  
  
  export default Memberout;