import { Table, Input, Button, Select, Modalimport, Space, Dropdown, Modal, Upload, message, Carousel } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite, ColorRed } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import TextArea from 'antd/es/input/TextArea';
import '../../../src/style.css'
import { DatePicker } from "antd";
import { DownOutlined, LoadingOutlined, UploadOutlined,PlusOutlined } from '@ant-design/icons';


import Footer from '../../Components/Footer';

dayjs.locale('ko');
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
function AdminTransactionform() {
    const navigate = useNavigate();
    const location = useLocation();
    const initialDetailState = {
      ct_name :'',
      pt_content : '',
      pt_idx : null,
      pt_image1 : '',
      pt_image2 : '',
      pt_image3 : '',
      pt_image4 : '',
      pt_image5 : '',
      pt_image6 : '',
      pt_image7 : '',
      pt_image8 : '',
      pt_image9 : '',
      pt_image10 : '',
      pt_selling_price : null,
      pt_show : '',
      pt_title : '',
      pt_wdate : '',
    }

    const [data, setData] = useState([]);
    const [detail,setDetail] = useState(()=>initialDetailState);
    const [dateTag, setDateTag] = useState(false);
    const [detailToggle, setDetailToggle] = useState(false);
    
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(30);
    const [mtype, setMtype] = useState('');
    const [tabBtn, setTabBtn] = useState(true);
    const [display, setDisplay] = useState('none');
    const [imgURLArr, setImgURLArr] = useState([]);
    const ImgURL = 'https://api.getgo.id/uploads/';
    const [no, setNumber] = useState(0);
    const idx = useParams();
    const id = idx.id
    
    const getData = async () => {
    
      
      try {
        const formdata = {
          page: page,
          amount: amount,
        
        };
        const token = sessionStorage.getItem('token');
       
        const res = await API.get('/admin/admin-category-list', { params: formdata, headers: { Authorization: `Bearer ${token}` } });
      
        
        setData(res.data.list);
        setTotal(res.data.total);
        const number = res.data.total-(page - 1) * amount;
       
        
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    };

    
    const getDetail = async (idx) => {
      try {
        const res = await API.get('admin/product-infolist?pt_idx='+idx);
        const resData = res.data
        console.log('getDetail: ',res)
        setDetail(resData.data[0])
        ImgCarousel(Object.values(resData.data[0]))
        console.log(`detail데이터`,resData.data[0])
       
        setDetailToggle(true)

      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error.response);
        }
      }
    }

    /* 이미지 배열 */
    let imgValArr = '';
    const ImgCarousel = (detailData) => {
      setImgURLArr([])
      imgValArr = detailData
      imgValArr.map((data, i) => {
        let imgName = `"${data}"`
        let imgNameLower = imgName.toLowerCase()
        if (imgNameLower.includes('png') || imgNameLower.includes('jpg') || imgNameLower.includes('jpeg') || imgNameLower.includes('bmp') || imgNameLower.includes('gif')) {
          imgURLArr.push(data)
          setImgURLArr((e) => [...e, data] )
        }
      })
      console.log(imgURLArr)
    }
    
 

    const onChangeImg = (currentSlide) => {
      console.log(currentSlide);
    };

    const onClose = () => {
      setImgURLArr([])
      setDetailToggle(false)
    }

    const onChange = useCallback((pagination, filters, extra) => {
      setPage(pagination.current);
    }, []);
  
    const onSelect = (value) => {
      setSelect(value);
    };
    
    useEffect(()=>{
      setDetailToggle(false)
    },[detail])

    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [page, amount]);


    useEffect(() => {
      if (location.state) {
        setPage(location.state.page);
        setSearch(location.state.search);
        setSelect(location.state.filter);
      }
    }, []);
    

    const onSelectChange = (newSelectedRowKeys) => {
      console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };
    
    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        <ItemContainer dp={detailToggle} style={{flex: 1/2}}>
            <Box style={{paddingRight: 0, paddingBottom: 20}}>
              <Wrap style={{justifyContent: 'space-between'}}>
                <Title style={{marginBottom: 10}}>상품정보</Title>
                <Button onClick={() => onClose()}>X</Button>
              </Wrap>
              <Carousel afterChange={onChangeImg}>
                {
                  imgURLArr.map((url,i) => {
                    console.log(i, url)
                    return (
                      <div>
                        <Img src={`${ImgURL}${url}`} key={`{img-}${i}`}  className='custom-bas'/>
                      </div>
                    )
                  })
                }
              </Carousel>
            </Box>
            <ItemBox>
              <ItemWrap style={{justifyContent: 'space-between'}}>
                <Tag>{detail.ct_name ? detail.ct_name : '카테고리 없음'}</Tag>
               
              </ItemWrap>
              <ItemWrap style={{display: 'block'}}>
                <ItemTitle>{detail.pt_title}</ItemTitle>
                <text>{detail.pt_content}</text>
                <Line/>
                <Price>가격 {detailToggle ? (detail.pt_selling_price).toLocaleString() : null }</Price>
              </ItemWrap>
            </ItemBox>
          </ItemContainer>
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>운영자 거래 등록</Title>
              </Wrap>
            </Wrap>
            <Wrap style={{ marginBottom: 30  }}>
              
            </Wrap>
            {tabBtn ? <MemberTable data={data} id={id}/> : <CountTable data={{data}}/>}
          </Box>
        </Container>
        {/* <Footer /> */}
      </div>
    );
  }
  
  function MemberTable(props) {
    const navigate = useNavigate();
    const [ct_id, setSelect] = useState('');
    const [pttitle, setpttitleUpdate] = useState('');
    const [ptcontent, setptcontentUpdate] = useState('');
    const [pturl, setpturlUpdate] = useState('');
    const [ptselleringprice, setptselleringpriceUpdate] = useState('');
    const [imageUrl, setImageUrl] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageName, setImageName] = useState([]);
    const [pt_date_yn, setUseshow] = useState("Y");
    const [modeDisabled, setModeDisabled] = useState(true);
    const [btnmodeDisabled, btnsetModeDisabled] = useState(true);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileCheck, setfileCheck] = useState([]);
     
    const profileImg = props.data.mt_image1
    const today = new Date();
    const year = today.getFullYear();
    const mm = today.getMonth()+1;
    const dd = today.setDate(today.getDate()+7);
    const hh = today.getDate();
 
    let month
console.log(fileCheck)
console.log(previewOpen)
console.log(previewImage)
console.log(previewTitle)
    useEffect(()=>{
      console.log(fileList)
     if(ct_id && pttitle && ptcontent && pturl && ptselleringprice && fileList.length > 0 ){
      btnsetModeDisabled(false);
     } 
      
 

    },[ct_id,pttitle,ptcontent,pturl,ptselleringprice,fileList])


  
  if(mm < 10){
    month = "0"+mm;
  }else{
    month = mm;
  }

  let day
  if(dd < 10){
    day = "0"+dd;
  }else{
    day = dd;
  }

  const save_date = year+'-'+month+'-'+day
  const today7 = new Date(dd);
 
    const [pt_reg_date, setSearchDate] = useState(today7);
    const [mdata, setData] = useState([]);
    const now = new Date;
  

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };
  const disabledDateTime = () => ({
    
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],

    
  });



  const onSearchDate = (e) => {
console.log(e);
    setSearchDate(e);
   
  };

    let type = '';


    
    const onOutBtn = () => {
      
    }

   
    
    

    const updateMember = async () => {
      let file_list = [];
      let contentType ='';
      let image_name = "";
      let image_url = "";
      let file="";
      if (imageUrl !== null) {

        for(let i=0;i < fileList.length;i++){
          image_name = fileList[i].name;
          image_url = fileList[i].thumbUrl;
          file = dataURLtoFile(image_url,image_name)
          file_list.push(file)
        }
        contentType = 'multipart/form-data';
      } else {
        file = profileImg
        contentType = 'application/json';
      }


       // const bodyData = {
      //   'mt_idx': props.id,
      //   'mt_hp': phoneUpdate,
      //   'mt_login_status': loginUpdate,
      //   'mt_nickname': nickUpdate,
      //   'mt_memo': memoUpdate,
      //   'mt_image1' : (imageUrl ? file : profileImg)
      // }
      // console.log(bodyData)
      try {
        const bodyData = new FormData();
        for(let c=0;c < fileList.length;c++){
          const k = c+1;
        bodyData.append('pt_image',file_list[c]);
        console.log(file_list[c])
        }
        bodyData.append('ct_id', ct_id,);
        bodyData.append('pt_reg_date', pt_reg_date);
        bodyData.append('pt_date_yn', pt_date_yn);
        bodyData.append('pt_admin_yn', "Y");
        bodyData.append('pt_title', pttitle);
        bodyData.append('pt_content', ptcontent);
        bodyData.append('pt_url', pturl);
        bodyData.append('pt_selling_price', ptselleringprice);
       
        
        for (let key of bodyData.keys()) {
          console.log(key, ":", bodyData.get(key));
        }
       
        const res = await API.post('admin/product-add', bodyData, {
          // headers : {'Content-Type' : 'application/json'},
          headers : {'Content-Type' : contentType},
        });
        Modal.success({ title: '거래 등록', content: '거래 등록이 완료되었습니다..', 
        onOk: () => navigate(`/AdminTransactionDetail/${res.data.pt_idx}`), okText: 'Ok' });
          
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      }
    };

    const onSaveBtn = () => {
      updateMember()
    }

    // base64 Data -> File 객체로 변환
 
    const dataURLtoFile = (dataurl, filename) => {
    // function dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }



    
    const changeValues = (e) => {
      
      const { value, name } = e.target;

      //  setInfoData((prev) => ({ ...prev, [type]: { ...prev[type], [index]: { ...prev[type][index], [name]: value } } }));
     };
/*
    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
      
    };

    const uploadImage = (info) => {
      
      setImageName(info.file.name)
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      setImageUrl({ url: info.file.originFileObj  });
     
       changeValues({ target: { name: 'mt_image1', value: info.file.originFileObj } });
    };

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      return isJpgOrPng;
    };
   */ 

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      setfileCheck('Y')
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList) ;

    const chkchangeValues = (e) => {
   
      const { value, name } = e.target;
     if(pt_date_yn == "Y"){
      setUseshow('N');
      setModeDisabled(false);
     }else{
      setUseshow(value);
      setModeDisabled(true);
     }
      
    };

    const onSelect = (value) => {
      setSelect(value);
      
    };

    const handleSetValue = (e) => {
      setptcontentUpdate(e.target.value);
    };
/*
    changeButton = () => {
      const { ct_id, pttitle,ptcontent,pturl,ptselleringprice,imageUrl } = this.state;
      ct_id && pttitle && ptcontent && pturl && ptselleringprice && imageUrl
        ? this.setState({ isActive: true })d
        : this.setState({ isActive: false });
    };
    */

    const uploadButton = <Button icon={loading ? <LoadingOutlined /> : <PlusOutlined />}>등록</Button>;

    

    // useEffect(() => {
    //   const imgURL = infoData.image;
    //   console.log(imgURL);
    //   if (typeof imgURL !== 'object') {
    //     setImageUrl(imgURL);
    //   } else {
    //     setImageUrl(null);
    //   }
    // }, [infoData.image]);

    return (
      <>
        <div style={{display: 'flex'}}>
          <table>
            <Tr>
              <Th>카테고리</Th>
              <Td>
              <Select 
                  type='Default'
                  value={ct_id ? ct_id : "선택"}
                  style={{ width: 200, textAlign: 'center' }}
                  onChange={onSelect}
                  options={props.data}
                />

              </Td>
              <Th>등록기간</Th>
              <Td><DatePicker  style={{ width: 250,height:40, marginLeft: '5px' }}
              id="search_date"
              format="YYYY-MM-DD"
              onChange={onSearchDate}
              // showNow={{defaultValue: dayjs('00:00:00'),}} 
              defaultValue={dayjs(today7)}
              disabled={modeDisabled}
              // format="YYYY-MM-DD"
             // disabledDate={disabledDate}
              // disabledTime={disabledDateTime}
          /> <label style={{ marginLeft: '20px' }}>
            <input type="checkbox"  checked={pt_date_yn === "Y"}  onChange={() => chkchangeValues({ target: { name: 'pt_date_yn', value: "Y" } })} /> 사용하지 않음
            </label>
            </Td>
            </Tr>
            <Tr>
              <Th>제목</Th>
              <Td colSpan={3}>
                <Input style={{ width: '100%',height:40 }} placeholder="제목울 입력하세요"
                  value={pttitle === '' ? (pttitle && setpttitleUpdate(pttitle)) : pttitle} 
                  onChange={(e) => { setpttitleUpdate(e.target.value)}}
                />
                
              </Td>
              
            </Tr>
            <Tr style={{height: 300}}>
              <Th>내용</Th>
              <Td colSpan={3}>
              <textarea
              placeholder="내용울 입력하세요"
             value={ptcontent}
             onChange={(e) => handleSetValue(e)} style={{width: '100%',height:280}}></textarea>
               
                
              </Td>
              
            </Tr>
            <Tr>
              <Th>URL</Th>
              <Td colSpan={3}>
                <Input style={{ width: '100%',height:40 }} placeholder="https://admin.getgo.id"
                  value={pturl === '' ? (pturl && setpturlUpdate(pturl)) : pturl} 
                  onChange={(e) => { setpturlUpdate(e.target.value)}}
                />
                
              </Td>
              
            </Tr>
            <Tr>
              <Th>가격</Th>
              <Td colSpan={3}>
                <Input style={{ width: 100,height:40 }}  type='number' placeholder="10000"
                  value={ptselleringprice === '' ? (ptselleringprice && setptselleringpriceUpdate(ptselleringprice)) : ptselleringprice} 
                  onChange={(e) => { setptselleringpriceUpdate(e.target.value)} }
                /> Rp
                
              </Td>
              
            </Tr>
            
            <Tr style={{marginTop: 30,height:"100%"}}>
              <Th>이미지</Th>
              <Td>
                {/* <img src={profileImg} 
                  crossOrigin='anonymous' className='custom-avt'
                /> */}
                <Upload name="ct_file1"  accept="image/jpg,image/png,image/jpeg,image/gif"   maxCount='10' listType="picture-card" multiple showUploadList={true} fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange} >
                {fileList.length >= 10 ? null : uploadButton} 
                
                </Upload>
       <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
              </Td>
            </Tr>
          </table>
          
        </div>
        <Line></Line>
        <Wrap style={{justifyContent: 'center'}}>
          <Button type='Default' style={{ width: 90, height: 40, marginRight: 10 }} 
            onClick={() => navigate(`/AdminTransactionlist`)}>목록</Button>
          <Button type='Default' style={{ width: 90, height: 40, }} onClick={() => onSaveBtn()} disabled={btnmodeDisabled}>저장</Button>
        </Wrap>
      </>
    )
  }

  function CountTable(props) {
    const data = props.data.data
    const itemlist = props.data.itemlist
    const columns = props.data.columns
    const navigate = useNavigate();
    return(
      <>
       
        
        
      </>
    )
  }
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;
  
  const Th = styled.th`
    width: 150px;
    margin-top: 20px;
  `
  
  const Tr = styled.tr`
    display: flex;
    // min-width: 700px;
    width: 100%;
    height: 80px;
    text-align: left;
    `
    
    const Td = styled.td`
    width: 500px;
    margin-top: 20px;
    // padding: 10px;
  `

  const Line = styled.div`
    border-bottom: 1px solid #d9d9d9;
    margin: 90px 0;
  `
  const Text = styled.p`
    padding: 10px 0 0 10px;
    margin: 0;
    color: #162d59;
  `
  const Message = styled.p`
    padding: 10px 0 0 10px;
    margin: 0;
    color: ${props => props.color};
  `

  const DeleteItem = styled.p`
    margin: 0;
    padding-left: 10px;
    color: ${ColorRed};
  `

  const ItemContainer = styled.div`
    display: ${props => props.dp === true ? 'block' : 'none'};
    flex: 1/2;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `

  const ItemBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 0 0 80px;
    background-color: ${ColorWhite};
  `;

  const ItemWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
  `;

  const Img = styled.img`
    width: 100%;
    height: 500px;
    background: #d9d9d9;
  `

  const Tag = styled.span`
    // width: 100px;
    height: auto;
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    // border-radius: 5px;
    padding: 0 10px;
    text-align: center;
    font-weight: 600;
  `
  const Time = styled.span`
    color: ${ColorMainBlue};
    // margin: 10px;
  `
  const ItemTitle = styled.h2`
    font-size: 20px;
    font-weight: 600;
  `
  const ItemLine = styled.div`
    width: auto;
    height: 1px;
    background: #d9d9d9;
    margin: 100px 0 20px 0;
  `
  const Price = styled.p`
    font-size: 20px;
    font-weight: 600;
  `

  
  export default AdminTransactionform;