import { Table, Input, Button, Select, Modalimport, Space, Dropdown, Modal, Upload, message, Carousel } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorMainBlue, ColorWhite, ColorRed } from '../../Utils/Color';
import { API } from '../../Utils/API';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import HeadersAdmin from '../../Components/HeadersAdmin';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import TextArea from 'antd/es/input/TextArea';
import '../../../src/style.css'
import { DatePicker } from "antd";
import { DownOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import Footer from '../../Components/Footer';

dayjs.locale('ko');

function AdminTransactionDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const initialDetailState = {
      ct_name :'',
      pt_content : '',
      pt_idx : null,
      pt_image1 : '',
      pt_image2 : '',
      pt_image3 : '',
      pt_image4 : '',
      pt_image5 : '',
      pt_image6 : '',
      pt_image7 : '',
      pt_image8 : '',
      pt_image9 : '',
      pt_image10 : '',
      pt_selling_price : null,
      pt_url : '',
      pt_title : '',
      pt_reg_date : '',
    }

    const [data, setData] = useState([]);
    const [detail,setDetail] = useState(()=>initialDetailState);
    const [dateTag, setDateTag] = useState(false);
    const [detailToggle, setDetailToggle] = useState(false);
    
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [filter, setSelect] = useState('all');
    const [page, setPage] = useState(1);
    const [amount, setAmount] = useState(30);
    const [mtype, setMtype] = useState('');
    const [tabBtn, setTabBtn] = useState(true);
    const [display, setDisplay] = useState('none');
    const [imgURLArr, setImgURLArr] = useState([]);
    const ImgURL = 'https://api.getgo.id/uploads/';
;
    const idx = useParams();
    const id = idx.id
    
    const getData = async () => {
        try {
          const res = await API.get('admin/product-detail?pt_idx='+id);
          const resData = res.data
          setData(...resData.data)
       
          console.log(res)
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.log(error.response);
          }
        }
      };
    
   

   
    

    useEffect(() => {
      const debounce = setTimeout(() => {
        return getData();
      }, 300);
      return () => clearTimeout(debounce);
    }, [data.pt_sale_now]);


   

   
    
    

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <HeadersAdmin></HeadersAdmin>
        
        <Container>
          <Box>
            <Wrap style={{ marginBottom: 30, justifyContent: 'space-between' }}>
              <Wrap>
                <Title>운영자 거래 등록</Title>
              </Wrap>
            </Wrap>
            <Wrap style={{ marginBottom: 30  }}>
              
            </Wrap>
              <ProductTable data={data} id={id}/> 
          </Box>
        </Container>
        {/* <Footer /> */}
      </div>
    );
  }
  
  function ProductTable(props) {
    console.log("pros",props)
    const navigate = useNavigate();
    const [ct_id, setSelect] = useState('');
    const [pttitle, setpttitleUpdate] = useState('');
    const [ptcontent, setptcontentUpdate] = useState('');
    const [pturl, setpturlUpdate] = useState('');
    const [ptselleringprice, setptselleringpriceUpdate] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageName, setImageName] = useState(false);
    const [pt_date_yn, setUseshow] = useState("Y");
    const [modeDisabled, setModeDisabled] = useState(true);
    const [pt_reg_date, setSearchDate] = useState();

    
    const profileImg = props.data.pt_image1
    const profileImg2 = props.data.pt_image2
    const profileImg3 = props.data.pt_image3
    const profileImg4 = props.data.pt_image4
    const profileImg5 = props.data.pt_image5
    const profileImg6 = props.data.pt_image6
    const profileImg7 = props.data.pt_image7
    const profileImg8 = props.data.pt_image8
    const profileImg9 = props.data.pt_image9
    const profileImg10 = props.data.pt_image10
    const pt_idx = props.data.pt_idx
    const reg_date = props.data.pt_reg_date
    const ct_name = props.data.ct_name
    const pt_title = props.data.pt_title
    const pt_content = props.data.pt_content
    const pt_url = props.data.pt_url
    const pt_price = props.data.pt_selling_price;
    const pt_sale_now = props.data.pt_sale_now
    

    
  
  
  



    const updateProduct = async (status,pt_idx) => {
        try {
          const res = await API.get(`admin/product-status?pt_idx=${pt_idx}&pt_sale_now=${status}`);
          const resData = res.data.message
          console.log(resData)
          Modal.success({ title: '거래상태 변경', content: '거래상태가 변경되었습니다..', 
          onOk: () => navigate(`/AdminTransactionDetail/${pt_idx}`), okText: 'Ok' });
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.log(error.response)
          }
        }
      };
   
    
    



    const onSaveBtn = (status,pt_idx) => {
          if(status == 1){
            Modal.confirm({ title: '거래 종료', content: '이 게시물을 종료하시겠습니까?" ', 
            onOk: () => updateProduct(3,pt_idx), okText: 'Ok' });
          }else{
            Modal.confirm({ title: '거래 진행', content: '이 게시물을 진행하시겠습니까?" ', 
            onOk: () => updateProduct(1,pt_idx), okText: 'Ok' });

          }
      
      
    }

    

    return (
      <>
        <div style={{display: 'flex'}}>
          <table>
          <Tr>
              <Th>번호</Th>
              <Td>
              {pt_idx}
            </Td>
            </Tr>
            <Tr>
              <Th>상태</Th>
              <Td>
              {pt_sale_now == 1 ? '진행중' : (pt_sale_now == 3) ? '종료' : '진행중'}
             
            </Td>
            </Tr>
            
            <Tr>
              <Th>등록기간</Th>
              <Td>
              {reg_date}
            </Td>
            </Tr>
            <Tr>
              <Th>카테고리</Th>
              <Td>
              {ct_name}
            </Td>
            </Tr>
            <Tr>
              <Th>제목</Th>
              <Td >
              {pt_title}
                
              </Td>
              
            </Tr>
            <Tr style={{height: '100%'}}>
              <Th>내용</Th>
              <Td >
              {pt_content}
               
                
              </Td>
              
            </Tr>
            <Tr>
              <Th>URL</Th>
              <Td >
              <a href={pt_url} target='_blank' rel='noreferrer'>{pt_url}</a>
                
              </Td>
              
            </Tr>
            <Tr>
              <Th>가격</Th>
              <Td >
              {pt_price ? (pt_price).toLocaleString() : null } Rp
                
              </Td>
              
            </Tr>
            
            <Tr style={{height: '100%'}}>
              <Th>이미지</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            {profileImg2 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지2</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg2}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg3 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지3</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg3}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg4 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지4</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg4}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg5 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지5</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg5}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg6 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지6</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg6}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg7 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지7</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg7}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg8 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지8</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg8}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg9 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지9</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg9}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
             {profileImg10 && ( // 호버 키값과 === 호버메시지의 id 값이 같으면
            <Tr style={{height: '100%'}}>
              <Th>이미지10</Th>
              <Td>
              <img src={`https://api.getgo.id/uploads/${profileImg10}`}  className='custom-bas2'/>
              
              </Td>
            </Tr>
            )}
          </table>
          
        </div>
        <Line></Line>
        <Wrap style={{justifyContent: 'center'}}>
          <Button type='Default' style={{ width: 90, height: 40, marginRight: 10 }} 
            onClick={() => navigate(`/AdminTransactionlist`)}>목록</Button>
          <Button type='Default' style={{ width: 90, height: 40, }} onClick={() => onSaveBtn(pt_sale_now,pt_idx)}>수정</Button>
        </Wrap>
      </>
    )
  }

  function CountTable(props) {
    const data = props.data.data
    const itemlist = props.data.itemlist
    const columns = props.data.columns
    const navigate = useNavigate();
    return(
      <>
       
        
        
      </>
    )
  }
  const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `;
  
  const Box = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 50px 80px;
    background-color: ${ColorWhite};
  `;
  const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
  
  const Title = styled.div`
    color: ${ColorMainBlue};
    font-size: 24px;
    font-weight: bold;
  `;
  
  const Th = styled.th`
    width: 150px;
    margin-top: 20px;
  `
  
  const Tr = styled.tr`
    display: flex;
    // min-width: 700px;
    width: 100%;
    height: 80px;
    text-align: left;
    `
    
    const Td = styled.td`
    width: 500px;
    margin-top: 20px;
    // padding: 10px;
  `

  const Line = styled.div`
    border-bottom: 1px solid #d9d9d9;
    margin: 90px 0;
  `
  const Text = styled.p`
    padding: 10px 0 0 10px;
    margin: 0;
    color: #162d59;
  `
  const Message = styled.p`
    padding: 10px 0 0 10px;
    margin: 0;
    color: ${props => props.color};
  `

  const DeleteItem = styled.p`
    margin: 0;
    padding-left: 10px;
    color: ${ColorRed};
  `

  const ItemContainer = styled.div`
    display: ${props => props.dp === true ? 'block' : 'none'};
    flex: 1/2;
    flex-direction: column;
    overflow: hidden;
    background-color: ${ColorWhite};
  `

  const ItemBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 0 0 80px;
    background-color: ${ColorWhite};
  `;

  const ItemWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
  `;

  const Img = styled.img`
    width: 100%;
    height: 500px;
    background: #d9d9d9;
  `

  const Tag = styled.span`
    // width: 100px;
    height: auto;
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    // border-radius: 5px;
    padding: 0 10px;
    text-align: center;
    font-weight: 600;
  `
  const Time = styled.span`
    color: ${ColorMainBlue};
    // margin: 10px;
  `
  const ItemTitle = styled.h2`
    font-size: 20px;
    font-weight: 600;
  `
  const ItemLine = styled.div`
    width: auto;
    height: 1px;
    background: #d9d9d9;
    margin: 100px 0 20px 0;
  `
  const Price = styled.p`
    font-size: 20px;
    font-weight: 600;
  `

  
  export default AdminTransactionDetail;